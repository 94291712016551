/* You can add global styles to this file, and also import other style files */

@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";
@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  @apply h-full scroll-auto;
}

body {
  @apply bg-[#f0f8ff] m-0 font-base text-black leading-normal text-sm 2xl:text-base;
}

body * {
  @apply tracking-[.02em];
}

input {
  @apply placeholder:text-neutral-500;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-weight: 600;
  @apply bg-white rounded-t-10;
  border-bottom: 1px solid #e4ebf5;
}

.mat-mdc-tab-group.mat-primary
  > .mat-mdc-tab-header
  .mat-mdc-tab
  .mdc-tab__text-label {
  color: #3367b1 !important;
}

.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.mdc-tab--active.ng-star-inserted.mdc-tab-indicator--active {
  background-color: #3367b1;
  .mdc-tab__text-label {
    color: #fff !important;
  }
}
.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.mat-mdc-tab-disabled {
  .mdc-tab__content .mdc-tab__text-label {
    color: #000 !important;
  }
}
mat-mdc-focus-indicator
  mdc-tab--active
  ng-star-inserted
  mdc-tab-indicator--active {
}

mat-tab-header.mat-mdc-tab-header {
  @apply sticky top-0 z-10;
}

.config {
  mat-panel-title {
    @apply font-semibold text-black-light;
  }
  .mat-expansion-indicator::after {
    @apply border-black;
  }
  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:not([aria-disabled="true"]):hover,
  .mat-expanded {
    .mat-expansion-indicator::after {
      @apply border-white;
    }
  }
}

.sidebar {
  .active {
    > .menu-item {
      svg {
        path {
          @apply fill-white;
        }
      }
      &:hover {
        svg {
          path {
            @apply fill-white;
          }
        }
      }
    }
  }
  .menu-item {
    svg {
      path {
        @apply text-black-light;
      }
    }

    &.active,
    &:hover {
      svg {
        path {
          @apply fill-primary;
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  -webkit-overflow-scrolling: auto;
  @apply w-3 h-0.5;
}

::-webkit-scrollbar-track {
  @apply rounded-md shadow-inner;
}

::-webkit-scrollbar-thumb {
  @apply bg-secondary rounded-md;
}

.large-scrollbar {
  &::-webkit-scrollbar {
    @apply w-2 h-2;
  }
}

.common-btn {
  @apply text-sm px-4 h-10 rounded-5;

  &.primary-btn {
    @apply bg-primary text-white;
  }

  &.secondary-btn {
    @apply bg-primary-light text-primary;
  }

  &:disabled {
    @apply bg-grey-50 text-grey-light cursor-not-allowed;
  }
}

.custom-slide-toggle {
  &.mat-mdc-slide-toggle {
    &.mat-accent {
      --mdc-switch-selected-track-color: #3367b1;
      --mdc-switch-selected-focus-state-layer-color: #3367b1;
      --mdc-switch-selected-handle-color: #fff;
      --mdc-switch-selected-hover-state-layer-color: #fff;
      --mdc-switch-selected-pressed-state-layer-color: #fff;
      --mdc-switch-selected-focus-handle-color: #fff;
      --mdc-switch-selected-hover-handle-color: #fff;
      --mdc-switch-selected-pressed-handle-color: #fff;
      --mdc-switch-selected-focus-track-color: #3367b1;
      --mdc-switch-selected-hover-track-color: #3367b1;
      --mdc-switch-selected-pressed-track-color: #3367b1;
    }
    .mdc-switch {
      .mdc-switch__handle-track {
        @apply w-3.5;
      }

      .mdc-switch__track {
        @apply rounded-3xl h-5;
      }

      .mdc-switch__handle {
        @apply left-1 h-3.5 w-3.5;

        .mdc-switch__ripple {
          @apply hidden;
        }
      }

      &:disabled {
        .mdc-switch__track {
          @apply opacity-100;

          &::before {
            @apply bg-grey-50;
          }
        }
      }
    }

    .mdc-switch__icon--on,
    .mdc-switch__icon--off {
      @apply hidden;
    }
  }
}

.custom-select {
  &.ng-select,
  &.ng-select-focused:not(.ng-select-opened) {
    .ng-select-container {
      @apply border-grey-50 shadow-none rounded-md text-secondary min-h-[40px] h-full;

      .ng-value-container {
        @apply pl-4 pr-2;

        .ng-input {
          @apply pl-4 top-2.5 translate-y-[-1/2];
        }

        .ng-placeholder {
          @apply text-neutral-500 font-light;
        }
      }
    }

    .ng-dropdown-panel {
      @apply border-grey-50;

      .ng-dropdown-panel-items {
        .ng-option {
          @apply text-secondary px-4 py-2;

          &.ng-option-selected {
            @apply bg-primary-light text-primary;
          }

          &.ng-option-marked {
            @apply bg-grey-50;
          }
        }
      }
    }

    .ng-arrow-wrapper {
      @apply pr-3;
    }
  }

  &.rounded {
    .ng-select-container {
      @apply rounded-md;
    }
  }

  &.field-error {
    .ng-select-container {
      @apply border-red text-red;

      .ng-arrow-wrapper {
        .ng-arrow {
          @apply border-t-red;
        }
      }
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      @apply bg-white;
    }
  }

  &.read-only {
    .ng-select-container {
      @apply bg-grey-100;
    }
  }

  &.tag-select {
    &.ng-select-focused:not(.ng-select-opened) {
      .ng-select-container {
        .ng-value-container {
          .ng-input {
            @apply pl-0;
          }
        }
      }
    }
  }
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        @apply py-1;
        .ng-placeholder {
          @apply pb-0 pl-0 top-1/2 -translate-y-1/2;
        }
        .ng-input {
          @apply pb-0 pl-0;
        }
        .ng-value {
          @apply my-0.5 mr-1.5 ml-0;
        }
      }
    }
  }
}

ng-select.hide-arrow {
  .ng-arrow-wrapper {
    @apply hidden;
  }
}

.generic-table-pagination {
  @apply font-base border-t border-grey-50 text-sm font-semibold pt-4;

  .mat-mdc-paginator-container {
    @apply justify-between p-0 min-h-[auto];
  }

  .mat-mdc-paginator-page-size-label {
    @apply m-0;
  }

  .mat-mdc-form-field {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      @apply border-grey-50;
    }

    &.mat-focused {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply border-grey-500;
      }

      .mat-mdc-select-arrow {
        @apply text-grey-500;
      }
    }
  }

  .mat-mdc-paginator-range-label {
    @apply m-0 mr-6 ml-3;
  }

  .mat-mdc-button-base {
    @apply p-0 h-8 w-8;
  }
}

.custom-matMenu {
  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      @apply min-h-[40px];

      .mdc-list-item__primary-text {
        @apply font-base text-sm 2xl:text-base font-semibold text-grey-500;
      }
    }
  }
}

.mat-ripple {
  @apply hidden;
}

.iti {
  @apply w-full;
  &.iti--separate-dial-code {
    @apply w-full font-semibold h-full;

    .iti__country {
      @apply px-3 py-2;
    }

    .iti__selected-flag {
      @apply bg-transparent pl-4 pr-2 border-r border-grey-50;
    }
  }
  .iti__search-input {
    @apply px-2 py-1.5 outline-none border-b;
  }
}

.mdc-snackbar {
  &.mat-mdc-snack-bar-container {
    --mat-snack-bar-button-color: inherit;
    .mdc-snackbar__surface {
      @apply bg-white pr-0 border-l-4;

      .mat-mdc-simple-snack-bar {
        @apply items-start;
      }

      .mdc-snackbar__label {
        @apply text-inherit font-base font-semibold;
      }

      .mdc-button {
        @apply min-h-[44px] min-w-[44px];
      }
    }
  }

  &.error-snackbar {
    .mdc-snackbar__surface {
      @apply border-red text-red;
    }
  }

  &.success-snackbar {
    .mdc-snackbar__surface {
      @apply border-green text-green;
    }
  }

  &.warning-snackbar {
    .mdc-snackbar__surface {
      @apply border-orange-400 text-orange-400;
    }
  }

  &.info-snackbar {
    .mdc-snackbar__surface {
      @apply border-blue-400 text-blue-400;
    }
  }
}

.field-lbl {
  @apply flex items-center text-grey-500 mb-1 leading-5 font-light text-sm;
  word-break: break-word;
}

.form-field {
  @apply w-full px-4 font-light text-secondary rounded-md border outline-0 min-h-[40px] border-grey-50;

  &:disabled {
    @apply bg-white;
  }
  &.read-only {
    @apply bg-grey-100 text-grey-light;
  }
}

.field-error {
  @apply border-red text-red;
}

.error-message {
  @apply text-red mt-1 text-[12px] md:text-sm font-semibold;
}

@media (max-width: 639px) {
  .generic-table-pagination {
    .mat-mdc-paginator-page-size {
      .mat-mdc-form-field {
        @apply m-0 w-[60px];

        .mat-mdc-text-field-wrapper {
          @apply px-2;
        }
      }
    }

    .mat-mdc-paginator-page-size-label {
      @apply hidden;
    }

    .mat-mdc-paginator-range-label {
      @apply mx-3;
    }
  }
}

.field-box {
  .iti__country-list {
    .iti__country {
      &.iti__active {
        @apply bg-primary-light text-primary font-semibold;
      }

      &.iti__highlight {
        @apply bg-grey-50;
      }
    }
  }
}

.btn-date-select {
  .mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 36px;
    @apply p-2;
  }
}

.custom-ngx-editor {
  @apply bg-white flex flex-col border border-grey-50 rounded-md overflow-hidden;
  ngx-editor {
    @apply flex-auto overflow-y-auto;
  }
  .NgxEditor__MenuBar {
    @apply border-b border-grey-50;
  }
  .NgxEditor {
    @apply border-none text-secondary h-full;
  }
  .NgxEditor__Content {
    @apply h-full;
  }
  ol,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    list-style: revert;
    padding: revert;
    margin: revert;
    line-height: normal;
  }

  a {
    color: revert;
    text-decoration: revert;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
  }

  &.field-error {
    @apply border-red;
  }
}

.dynamic-form {
  .cdk-drag-preview {
    @apply p-1 box-border rounded shadow;
  }
  .cdk-drag-animating {
    @apply transition;
  }
}

mat-dialog-container {
  &.mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      @apply max-h-[80vh];
    }
  }
}

.vjs-control {
  &.vjs-seek-to-live-control {
    display: none;
  }
}

textarea {
  @apply h-32;
}

input::-ms-reveal {
  @apply hidden;
}

.custom-options-height {
  .ng-dropdown-panel-items.scroll-host {
    @apply max-h-[580px];
  }
}

.ng-dropdown-panel {
  @apply border-grey-50;
}

.video-js {
  .vjs-tech {
    @apply static;
  }
}

.artefact-form {
  .form-field,
  .custom-select.ng-select .ng-select-container {
    @apply rounded-md;
  }
  .no-rounded-left {
    &.ng-select {
      .ng-select-container {
        @apply rounded-l-none;
      }
    }
  }

  .description {
    .mat-mdc-checkbox {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 100%;
      .mat-internal-form-field,
      .ng-untouched.ng-pristine.ng-valid {
        @apply h-full;
      }
    }
  }
}
#mat-tab-content-0-1,
#mat-tab-content-1-1 {
  form {
    .artefact-form {
      @apply flex-col;
    }
  }
}

.textarea-sizer {
  @apply inline-grid relative;
  textarea {
    @apply rounded-md border outline-0 border-grey-50 overflow-hidden;
    &.no-rounded-left {
      @apply rounded-l-none;
    }
  }
  &::after,
  textarea {
    @apply font-light text-secondary px-4 py-2 m-0 resize-none appearance-none min-h-24 h-auto w-full;
    grid-area: 2 / 1;
  }

  &::after {
    content: attr(data-value) " ";
    @apply invisible break-all whitespace-pre-wrap;
  }
}

mat-accordion {
  .mat-expansion-panel {
    .mat-expansion-panel-header {
      @apply bg-white px-4 py-2 h-auto min-h-12;
      &.mat-expanded {
        @apply text-white;
      }
    }
    @apply shadow-none;
    .mat-expansion-panel-body {
      @apply p-4 lg:p-6 2xl:p-8;
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-pane {
    &.mat-mdc-dialog-panel {
      @apply max-w-[calc(100%-40px)];
      .mat-mdc-dialog-container {
        @apply max-w-full;
      }
    }
  }
}

mat-checkbox {
  &.no-ripple {
    .mdc-checkbox {
      @apply p-0;
      .mat-mdc-checkbox-touch-target {
        @apply hidden;
      }
      .mdc-checkbox__native-control {
        @apply h-5 w-5;
      }
      .mdc-checkbox__background {
        @apply left-0 top-0;
      }
    }
  }
}
